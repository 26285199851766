import forge from 'node-forge';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export function checkPermission(checking) {
	// eslint-disable-next-line prefer-destructuring
	const user = JSON.parse(localStorage.getItem('ptl_powered_whitecrust_admin_other_data')).user;
	let response = false;
	if (!checking) {
		response = true;
	} else if (user.roles && user.roles.length > 0) {
		// eslint-disable-next-line prefer-destructuring
		const permissions = user.roles[0].permissions;
		if (user.roles[0].name === 'super admin') {
			response = true;
		} else {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < permissions.length; i++) {
				if (permissions[i].name === checking) {
					response = true;
					break;
				}
			}
		}
	} else {
		response = false;
	}
	return response;
}

export function getPermission(item) {
	let response = '';
	if (item) {
		if (item.stage.stage === '1') {
			response = 'loan approval';
		} else if (item.stage.stage === '2') {
			response = 'create offer letter';
		} else if (item.stage.stage === '3') {
			response = 'loan disbursement';
		} else if (item.stage.stage === '4') {
			response = 'update repayments';
		}
	}
	return response;
}

export const encryptRequest = (value) => {
	// Generate a random AES key (256-bit for AES-GCM)
	const aesKey = forge.random.getBytesSync(32);

	// Generate a random nonce for AES-GCM
	const nonce = forge.random.getBytesSync(12); // 96-bit nonce for AES-GCM

	// Encrypt the value using AES-GCM
	const cipher = forge.cipher.createCipher('AES-GCM', aesKey);
	cipher.start({ iv: nonce }); // 'iv' is synonymous with 'nonce' in forge
	cipher.update(forge.util.createBuffer(value, 'utf8'));
	cipher.finish();
	const encrypted = cipher.output.getBytes();
	const tag = cipher.mode.tag.getBytes(); // Authentication tag

	// Now, encrypt the AES key using RSA-OAEP
	const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsEbCqsKBelt+WyCXEKhJ
/hcr2CZ2DbXH6b7aly7zQkoRX79IOfJ3AZne7fMzEXOQGMHSWUmbo8rpBcyZ+T6M
ZcXe1FJPXRw+76lctB3ulJ2C/XwhpqyjTRm7V6jQpkc8nuPbnBJiw11skwa5+pJ0
GHedalXMxdWQziB6ZMm/IoxVyCoq134BCQfuowJeF8Hqe3ENZSbwd6STGa/KvdGf
C0aYpNA1Upawxi4++QdU7C2sgT2g0yKpdsTCJkpjGC4KzaORdIzUePfvRe4z/Muj
c6eqtviyR0aUoksojI0L4DAAH9dW2dToww/32KdRakYgG2EE0u3aUKqYJKyOKqjB
rwIDAQAB
-----END PUBLIC KEY-----`;

	const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
	const encryptedAESKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
		md: forge.md.sha256.create(),
		mgf1: {
			md: forge.md.sha1.create(),
		},
	});

	// Base64-encode the encrypted AES key, nonce, and tag for transmission
	const encodedAESKey = forge.util.encode64(encryptedAESKey);
	const encodedNonce = forge.util.encode64(nonce); // Renamed to nonce
	const encodedEncryptedData = forge.util.encode64(encrypted);
	const encodedTag = forge.util.encode64(tag);

	// Send the encrypted AES key, nonce, tag, and encrypted data
	return {
		aesKey: encodedAESKey,
		nonce: encodedNonce, // Using nonce instead of IV
		encrypted_data: encodedEncryptedData,
		tag: encodedTag,
	};
};

export const convertFileToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			resolve(reader.result); // Base64 string
		};

		reader.onerror = (error) => {
			reject(error); // Handle error
		};

		reader.readAsDataURL(file); // Convert file to Base64
	});
};

export function getFileExtensionFromBase64(base64String) {
	const match = base64String.match(/^data:(.*);base64,/);
	if (match && match[1]) {
		const mediaType = match[1];
		const extension = mediaType.split('/')[1];
		return extension;
	}
	return null;
}