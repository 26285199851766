import React from 'react';
import { Navigate } from 'react-router-dom';
import { authMenu } from '../menu';

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ isUserLoggedIn, children }) => {
	return isUserLoggedIn ? children : <Navigate to={authMenu.login.path} />;
};

export default ProtectedRoute;
