export const links = {
	// bearerUrl: 'https://whitecrust.test/api/v1',
	// galleryUrl: 'https://whitecrust.test/storage',
	bearerUrl: 'https://api.whitecrustng.org/api/v1',
	galleryUrl: 'https://api.whitecrustng.org/storage',
	pages: {
		login: 'auth/login',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'admin/profile',
		logout: 'logout',
		loans: 'admin/loans',
		offers: 'admin/offers',
		repayments: 'admin/repayments',
		liquidate: 'admin/loans/liquidate/loan',
		markAspaid: 'admin/loans/markAsPaid/loan',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		agents: 'admin/agents',
		customers: 'admin/customers',
		approval: 'admin/agents/approval',
		perm: 'admin/users/perm',
		notifications: 'admin/notifications',
		sms: 'admin/sms',
		smsFile: 'admin/sms-file',
		markMethodAsAttached: 'admin/offers/markAttached/pay_methods',
		deleteMethod: 'admin/offers/delete/pay_methods',
		downloadStatement: 'admin/loans/statements/account',
		roles: 'admin/roles',
		permissions: 'admin/permissions',
		invite_links: 'admin/invite_links',
		administrators: 'admin/administrators',
		admin_invite: 'admin/create',
		verify_invite_link: 'admin/invite/verify',
		join_team: 'admin/administrators/store',
		profile_update: 'profile/update',
		loanReport: 'admin/reports/loan_report',
		accountReport: 'admin/reports/loan_accounts',
		customerReport: 'admin/reports/customer_report',
		offerReport: 'admin/reports/offer_report',
		dashboard: 'admin/dashboard',
		loanGeminiUtils: 'gemini_loan_creation',
		listUssd: 'admin/ussd_lists',
		processUssd: 'admin/process-ussd',
		deleteSalary: 'admin/delete-jobs',
		processLoan: 'admin/loans/process-loan',
		uploadFile: 'admin/loan-file-upload',
		deleteFile: 'admin/loan-file-delete',
	},
	apis: {},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
